import analytics from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import {attributesToForm} from '@scavatec/timeclock-lib/api/form-builder'
import {useActiveReport} from '@scavatec/timeclock-lib/api/report'
import {useTransactionDeleteMutation, useTransactionUpdateMutation} from '@scavatec/timeclock-lib/api/transaction'
import {RemoteReportTimeEntry} from '@scavatec/timeclock-lib/api/types'
import {relationOrNull} from '@scavatec/timeclock-lib/api/util'
import {findTransaction, useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {useEmployees} from '@scavatec/timeclock-lib/hooks/entity-hooks'
import {useCustomForm} from '@scavatec/timeclock-lib/state/form-builder'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {CustomUserForm} from 'custom-forms'
import {DateTime} from 'luxon'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import {ConfirmationButton} from 'ui/confirmation-button'
import {Form} from 'ui/forms'
import {SettingDivider} from 'ui/settings'
import Toolbar from 'ui/toolbar'
import env from 'util/env'
import {useToastContext} from '../../contexts/toast-context'
import EmptyState from '../../ui/empty-state'
import TransactionForm, {TimeEntryFormPayload, transactionToEdit} from '../../ui/forms/transaction-form'
const pageId = 'w.c.4.1'

type TransactionEditProps = {
  timeEntry: MappedTransaction | RemoteReportTimeEntry
}
export const TransactionEdit: React.FC<TransactionEditProps> = ({timeEntry}) => {
  const navigate = useNavigate()
  const {actions} = useReportContext()
  const {addItem} = useToastContext()
  const {query} = useActiveReport()

  const initialFields = useMemo(() => {
    return transactionToEdit(timeEntry)
  }, [])

  const form = useForm<TimeEntryFormPayload>({
    defaultValues: initialFields,
  })
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: {dirtyFields},
    clearErrors,
    setError,
  } = form
  const customForm = useCustomForm('transactionStore', {
    enabled: env.features.customAttributes,
  })

  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')

  useEffect(() => {
    if (customForm) {
      const initial = transactionToEdit(timeEntry)
      reset({
        ...initial,
        relationships: {
          ...initial.relationships,
          customForm: {
            data: attributesToForm(customForm, {}),
          },
        },
      })
    }
  }, [customForm])
  const id =
    reportEngineSetting.value < 2
      ? (timeEntry as MappedTransaction).transactionUUID
      : (timeEntry as RemoteReportTimeEntry).id
  const updateMutation = useTransactionUpdateMutation(id, initialFields.relationships.employee ?? '', {
    onMutate: (values) => {
      analytics.event(`${pageId}-submit-update`, {
        date: values.dateUtc ?? false,
        payRate: values.payRate ?? false,
        note: (values.note ?? '').length > 0,
        clock_type: values.type ?? false,
        tips: values.tips ?? false,
        employee: values.relationships?.employee?.data?.id ?? '',
        job: values.relationships?.job?.data?.id ?? '',
      })
    },
    onSuccess: async (res, values) => {
      addItem(
        {
          title: `Updated transaction`,
          icon: 'save',
        },
        4000,
      )
      if (reportEngineSetting.value < 2) {
        actions.refresh()
      } else {
        query.refetch()
      }
      navigate(-1)
    },
    onError: (error) => {
      if (error instanceof APIValidationError) {
        Object.keys(error.errors).forEach((key) => {
          setError(`${key}` as any, {
            type: 'validation',
            message: error.errors[key],
          })
        })
      }
    },
  })

  const deleteMutation = useTransactionDeleteMutation({
    onMutate: (data) => {
      analytics.event(`${pageId}-view`)
    },
    onSuccess: (data) => {
      addItem({title: `Transaction deleted`, icon: 'trash'}, 4000)
      actions.refresh()
      navigate(-1)
    },
  })

  const onSubmit = useCallback(
    async (values: TimeEntryFormPayload) => {
      const {relationships, id, ...payload} = values
      const {relationships: dirtyRelationships, id: _dirtyId, ...dirtyKeys} = dirtyFields
      let fixedDates = {}
      if (dirtyKeys.hasOwnProperty('dateUtc') || dirtyKeys.hasOwnProperty('timezone')) {
        fixedDates = {
          dateUtc: DateTime.fromISO(values.dateUtc).setZone(values.timezone, {keepLocalTime: true}).toUTC().toISO(),
          dateUtcRounded: DateTime.fromISO(values.dateUtcRounded)
            .setZone(values.timezone, {keepLocalTime: true})
            .toUTC()
            .toISO(),
        }
      }
      const changedData = Object.keys(dirtyKeys).reduce<any>((acc, key) => {
        if (payload.hasOwnProperty(key)) {
          acc[key] = (payload as any)[key]
        }
        return acc
      }, {})
      const changedRelations = Object.keys(dirtyRelationships ?? {}).reduce<any>((acc, key) => {
        if (relationships.hasOwnProperty(key)) {
          acc[key] = {data: {id: relationships[key as keyof typeof relationships] ?? null}}
        }
        return acc
      }, {})
      updateMutation.mutate({
        ...changedData,
        ...fixedDates,
        relationships: {
          ...changedRelations,
        },
      })
    },
    [customForm, dirtyFields],
  )

  const submitting = false
  const pageId = ''
  return (
    <div className="page time-entry-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              color="grey"
              mode="secondary"
              label="Cancel"
              disabled={submitting}
              icon="close"
              onClick={() => {
                analytics.event(`${pageId}-click-cancel`)
                window.history.back()
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group align="right">
            <ConfirmationButton
              title="Confirm Delete"
              description="Are you sure you want to delete this transaction?"
              renderButton={(onClick) => {
                return <Button color="danger" label="Delete" icon="trash" disabled={submitting} onClick={onClick} />
              }}
              onConfirm={() => {
                if (reportEngineSetting.value < 2) {
                  const entry = timeEntry as MappedTransaction
                  deleteMutation.mutate({
                    id: entry.transactionUUID,
                    employee: entry.employeeUUID,
                  })
                } else {
                  const entry = timeEntry as RemoteReportTimeEntry
                  deleteMutation.mutate({
                    id: entry.id,
                    employee: entry.employeeId,
                  })
                }
              }}
            />
            <Button
              type="submit"
              disabled={submitting}
              loading={submitting}
              label="Update"
              icon="pencil"
              onClick={() => {
                clearErrors()
                handleSubmit(onSubmit)()
              }}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>
      <div className="page-content">
        {updateMutation.isError && <ErrorCallout error={updateMutation.error} />}
        {deleteMutation.isError && <ErrorCallout error={deleteMutation.error} />}
        <Form
          onSubmit={() => {
            clearErrors()
            handleSubmit(onSubmit)()
          }}
        >
          <div className="split-header">
            <h1>{`Edit Transaction`}</h1>
          </div>
          <TransactionForm pageId={pageId} mode="create" form={form} />
          <div className="row-spacer" />
          <SettingDivider label="Custom Properties" />
          <CustomUserForm
            control={control as any}
            prefix="relationships.customForm.data"
            formName="transactionUpdate"
          />
        </Form>
      </div>
    </div>
  )
}

type TimeEntryEditPageProps = {}
export const TimeEntryEditPage: React.FC<TimeEntryEditPageProps> = ({}) => {
  const {uuid} = useParams()
  const {rawReport} = useReportContext()
  const {findTimeEntry, query} = useActiveReport()
  const employees = useEmployees()
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')

  const timeEntry = useMemo(() => {
    if (!uuid) {
      return null
    }
    if (reportEngineSetting.value < 2) {
      const t = findTransaction(rawReport?.transactions ?? [], uuid, employees)
      return t
    } else {
      return findTimeEntry(uuid)
    }
  }, [rawReport, uuid, findTimeEntry, reportEngineSetting.value])
  useEffect(() => {
    analytics.event(`${pageId}-view`)
  }, [])
  if (!timeEntry) {
    return (
      <div className="page transaction-edit">
        <div className="page-content">
          <EmptyState
            title="Time Entry not found"
            description="The Time Entry attempting to be edited could no longer be found."
          />
        </div>
      </div>
    )
  }
  return <TransactionEdit timeEntry={timeEntry} />
}
export default TimeEntryEditPage
