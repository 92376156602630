import analytics from '@scavatec/timeclock-lib/analytics'
import {CustomAttributesFormPayload} from '@scavatec/timeclock-lib/api/form-builder'
import {
  RemoteReportTimeEntry,
  RemoteTimeEntry,
  RemoteTimeEntryFields,
  TimeEntryUpdatePayload,
} from '@scavatec/timeclock-lib/api/types'
import {useDataContext} from '@scavatec/timeclock-lib/contexts/data-context'
import {ClockType} from '@scavatec/timeclock-lib/database/models/common'
import Employee from '@scavatec/timeclock-lib/database/models/employee'
import Job, {NONE_UUID} from '@scavatec/timeclock-lib/database/models/job'
import {createTransaction, TransactionDoc, TransactionType} from '@scavatec/timeclock-lib/database/models/transaction'
import {useEmployees, useJobs} from '@scavatec/timeclock-lib/state/entities'
import {DateTime} from 'luxon'
import {groupBy} from 'rambda'
import React, {useEffect, useMemo} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {UseMutationResult} from '@tanstack/react-query'
import CurrencyInput from 'ui2/inputs/currency-input'
import SelectInput from 'ui2/inputs/select-input'
import TextAreaInput from 'ui2/inputs/text-area-input'
import Button from '../button'
import Callout from '../callout'
import * as Forms from '../forms'
import {TimeInput} from '../inputs/time-input'
import {timezoneOptions} from '../pickers/timezone-picker'

export type TimeEntryFormPayload = RemoteTimeEntryFields & {
  relationships: {
    employee: string | null
    job: string | null
    customForm?: {
      data: CustomAttributesFormPayload
    }
  }
}

export const createTimeEntry = (): TimeEntryFormPayload => {
  return {
    ...createTransaction({}),
    relationships: {
      employee: null,
      job: null,
    },
  }
}

const noneToNull = (id: string) => (id === NONE_UUID ? null : id)
export const transactionToEdit = (item: MappedTransaction | RemoteReportTimeEntry): TimeEntryFormPayload => {
  // Parse a legacy 1.0 api transaction
  if (item.hasOwnProperty('transactionUUID')) {
    const t = item as MappedTransaction
    const userBrowserZone = DateTime.local().zoneName
    return {
      type: t.type === ClockType.In ? TransactionType.In : TransactionType.Out,
      dateUtc: DateTime.fromISO(t.absoluteDateUTC, {zone: t.timeZone})
        .setZone(userBrowserZone, {keepLocalTime: true})
        .toISO(),
      dateUtcRounded: DateTime.fromISO(t.dateUTC, {zone: t.timeZone})
        .setZone(userBrowserZone, {keepLocalTime: true})
        .toISO(),
      note: t.note ?? '',
      payRate: t.payRate.toString(),
      tips: t.tips.toString(),
      latitude: parseFloat(t.latitude),
      longitude: parseFloat(t.longitude),
      locationAccuracy: parseFloat(t.locationAccuracy),
      timezone: t.timeZone,
      id: t.transactionUUID,
      relationships: {
        employee: t.employeeUUID,
        job: noneToNull(t.taskUUID),
      },
    }
  }
  // Parse a modern 2.0+ transaction
  const t = item as RemoteReportTimeEntry
  return {
    type: t.type,
    dateUtc: t.dateUtc,
    dateUtcRounded: t.dateUtcRounded,
    id: t.id,
    tips: t.tips,
    latitude: t.latitude,
    longitude: t.longitude,
    locationAccuracy: t.locationAccuracy,
    payRate: t.payRate,
    timezone: t.timezone,
    note: t.note ?? '',
    relationships: {
      employee: t.employeeId,
      job: noneToNull(t.jobId),
    },
  }
}

const employeeSelectOptions = (e: readonly Employee[]) =>
  e.map((e) => ({
    value: e.uuid,
    label: e.formattedName(),
  }))
const jobSelectOptions = (j: readonly Job[]) =>
  j.map((j) => ({
    value: j.uuid,
    label: j.name,
  }))

type TransactionFormProps = {
  pageId?: string
  mode: 'create' | 'update'
  form: UseFormReturn<any>
}
export const TransactionForm: React.FC<TransactionFormProps> = ({mode, form, pageId = ''}) => {
  const {control, setValue, watch} = form
  const values: TimeEntryFormPayload = watch()
  const jobs = useJobs()
  const employees = useEmployees()
  const employeeOptions = useMemo(() => {
    const grouped = groupBy((e) => (e.isActive ? 'active' : 'inactive'), employees)
    return [
      {
        label: 'Active',
        options: employeeSelectOptions(grouped['active'] ?? []),
      },
      {
        label: 'Inactive',
        options: employeeSelectOptions(grouped?.inactive ?? []),
      },
    ]
  }, [employees])
  const jobOptions = useMemo(() => {
    const grouped = groupBy(
      (e) => (e.isActive ? 'active' : 'inactive'),
      jobs.filter((j) => j.uuid !== NONE_UUID),
    )
    return [
      {label: 'Active', options: jobSelectOptions(grouped?.active ?? [])},
      {
        label: 'Inactive',
        options: jobSelectOptions(grouped?.inactive ?? []),
      },
    ]
  }, [jobs])

  return (
    <>
      <Forms.Row>
        <Forms.Calendar
          disabled={{after: DateTime.local().toJSDate()}}
          label="Date"
          className={values.type === TransactionType.Out ? 'selected-red' : 'selected-green'}
          value={values.dateUtc}
          onValueChange={(v) => {
            analytics.event(`${pageId}-select-date`)
            const curr = DateTime.fromISO(values.dateUtc)
            const dt = DateTime.fromISO(v).set({
              minute: curr.minute,
              hour: curr.hour,
            })
            setValue('dateUtc', dt.toISO(), {shouldDirty: true})
            setValue('dateUtcRounded', dt.toISO(), {shouldDirty: true})
          }}
        />
        <Forms.Col>
          <TimeInput
            label="Time"
            error=""
            value={values.dateUtc}
            onValueChange={(v) => {
              const next = DateTime.fromISO(v)
              let dt = DateTime.fromISO(values.dateUtc)
              dt = dt.set({
                minute: next.minute,
                hour: next.hour,
              })
              analytics.event(`${pageId}-select-time`)
              setValue('dateUtc', dt.toISO(), {shouldDirty: true, shouldTouch: true})
              setValue('dateUtcRounded', dt.toISO(), {shouldDirty: true, shouldTouch: true})
            }}
          />
          <SelectInput
            label="Timezone"
            placeholder="Select Timezone"
            options={timezoneOptions}
            name="timezone"
            control={control as any}
            rules={{required: true}}
          />
          <div className="form-input clock-type">
            <label>Clock Type</label>
            <div className="row">
              <Button
                label="IN"
                color="clock-in"
                className={values.type === TransactionType.Out ? 'outline' : ''}
                onClick={() => {
                  analytics.event(`${pageId}-click-clock-type-in`)
                  setValue('type', TransactionType.In, {shouldDirty: true})
                }}
              />
              <Button
                label="OUT"
                color="clock-out"
                className={values.type === TransactionType.In ? 'outline' : ''}
                onClick={() => {
                  analytics.event(`${pageId}-click-clock-type-out`)
                  setValue('type', TransactionType.Out, {shouldDirty: true})
                }}
              />
            </div>
          </div>
        </Forms.Col>
      </Forms.Row>
      <Forms.Row>
        <SelectInput
          label="Employee"
          placeholder="Select a Employee"
          options={employeeOptions}
          name="relationships.employee"
          control={control as any}
          onChange={(uuid) => {
            const employee = employees.find((emp) => emp.uuid === uuid)
            analytics.event(`${pageId}-select-employee`)
            setValue('payRate', employee?.payRate ?? 0.0, {shouldDirty: true})
          }}
          rules={{required: true}}
        />
        <SelectInput
          label="Job"
          placeholder="Select a Job"
          options={jobOptions}
          name="relationships.job"
          clearable
          control={control as any}
        />
      </Forms.Row>
      <Forms.Row>
        <CurrencyInput name="payRate" control={control as any} label="Hourly Pay Rate" placeholder="1122" />
        <CurrencyInput name="tips" control={control as any} label="Tips" placeholder="1122" />
      </Forms.Row>
      <Forms.Row>
        <TextAreaInput
          label="Note (visible to admin and employees)"
          placeholder="Transaction details"
          control={control as any}
          name="note"
        />
      </Forms.Row>
    </>
  )
}
export default TransactionForm
