import analytics from '@scavatec/timeclock-lib/analytics'
import {APIValidationError} from '@scavatec/timeclock-lib/api/client'
import {useActiveReport} from '@scavatec/timeclock-lib/api/report'
import * as client from '@scavatec/timeclock-lib/api/transaction'
import {relationOrNull} from '@scavatec/timeclock-lib/api/util'
import {useReportContext} from '@scavatec/timeclock-lib/contexts/report-context'
import {useCustomForm} from '@scavatec/timeclock-lib/state/form-builder'
import {useOrganizationSetting} from '@scavatec/timeclock-lib/state/settings'
import {useToastContext} from 'contexts/toast-context'
import {CustomUserForm} from 'custom-forms'
import {DateTime} from 'luxon'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import Button from 'ui/button'
import {ErrorCallout} from 'ui/callout'
import {Form} from 'ui/forms'
import TransactionForm, {createTimeEntry, TimeEntryFormPayload} from 'ui/forms/transaction-form'
import {SettingDivider} from 'ui/settings'
import Toolbar from 'ui/toolbar'
import env from 'util/env'

const pageId = 'w.c.4.0'

type TransactionState = {
  employee?: string
  job?: string
  payRate?: string
}
type TransactionNewProps = {}
export const TransactionNew: React.FC<TransactionNewProps> = ({children}) => {
  const {actions} = useReportContext()
  const {addItem} = useToastContext()
  const location = useLocation()
  const state = location.state as TransactionState
  const {query} = useActiveReport()
  const form = useForm<TimeEntryFormPayload>({
    defaultValues: {
      ...createTimeEntry(),
      timezone: DateTime.local().zoneName,
      payRate: state?.payRate?.toString() ?? '',
      relationships: {
        employee: state?.employee ?? null,
      },
    },
  })
  const {control, handleSubmit, setError, clearErrors, reset, watch} = form
  const submitting = false
  const navigate = useNavigate()
  const reportEngineSetting = useOrganizationSetting('reporting.engine_version')

  const customForm = useCustomForm('transactionStore', {
    enabled: env.features.customAttributes,
  })
  const createMutation = client.useTransactionCreateMutation(false, {
    onSuccess: async (res, values) => {
      addItem(
        {
          title: `Created transaction`,
          icon: 'save',
        },
        4000,
      )
      if (reportEngineSetting.value < 2) {
        actions.refresh()
      } else {
        query.refetch()
      }
      navigate(1)
    },
    onError: (error) => {
      if (error instanceof APIValidationError) {
        Object.keys(error.errors).forEach((key) => {
          setError(`${key}` as any, {
            type: 'validation',
            message: error.errors[key],
          })
        })
      }
    },
  })

  const submit = async (values: TimeEntryFormPayload, another: boolean) => {
    analytics.event(`${pageId}-submit-update`, {
      add_another: another,
      date: values.dateUtc,
      payRate: values.payRate,
      note: values.note.length > 0,
      clock_type: values.type,
      tips: values.tips,
      employee: values.relationships.employee,
      job: values.relationships.job,
    })
    try {
      const {
        relationships: {employee, job, ...other},
        dateUtc,
        dateUtcRounded,
        ...data
      } = values
      await createMutation.mutateAsync({
        ...data,
        dateUtc: DateTime.fromISO(dateUtc).setZone(data.timezone, {keepLocalTime: true}).toUTC().toISO(),
        dateUtcRounded: DateTime.fromISO(dateUtcRounded).setZone(data.timezone, {keepLocalTime: true}).toUTC().toISO(),
        relationships: {
          employee: {
            data: relationOrNull(employee),
          },
          job: {
            data: relationOrNull(job),
          },
          device: {
            data: null,
          },
          ...(other as any),
        },
      })
      if (another) {
        reset({
          ...createTimeEntry(),
          timezone: DateTime.local().zoneName,
        })
      } else {
        navigate(-1)
      }
    } catch (e) {
      console.log('failed', e)
    }
  }

  const saveAddAnother = async (values: TimeEntryFormPayload) => {
    submit(values, true)
  }

  const save = async (values: TimeEntryFormPayload) => {
    submit(values, false)
  }

  useEffect(() => {
    analytics.event(`${pageId}-view`)
  }, [])
  return (
    <div className="page time-entry-edit">
      <Toolbar.Bars>
        <Toolbar.Bar>
          <Toolbar.Group>
            <Button
              color="grey"
              mode="secondary"
              label="Cancel"
              disabled={submitting}
              icon="close"
              onClick={() => {
                analytics.event(`${pageId}-click-cancel`)
                window.history.back()
              }}
            />
          </Toolbar.Group>
          <Toolbar.Group align="right">
            <Button
              disabled={createMutation.isLoading}
              loading={createMutation.isLoading}
              label="Save & Add Another"
              mode="secondary"
              icon="plus"
              onClick={() => {
                clearErrors()
                handleSubmit(saveAddAnother)()
              }}
            />
            <Button
              type="submit"
              disabled={submitting}
              loading={submitting}
              label="Save"
              icon="plus"
              onClick={() => {
                clearErrors()
                handleSubmit(save)()
              }}
            />
          </Toolbar.Group>
        </Toolbar.Bar>
      </Toolbar.Bars>
      <Form onSubmit={() => handleSubmit(save)}>
        <div className="page-content">
          {createMutation.isError && <ErrorCallout error={createMutation.error} />}
          <div className="split-header">
            <h1>{`New Transaction`}</h1>
          </div>
          <TransactionForm pageId={pageId} mode="create" form={form} />
          <div className="row-spacer" />
          {customForm && <SettingDivider label="Custom Properties" />}
          <CustomUserForm control={control as any} prefix="relationships.customForm.data" formName="transactionStore" />
        </div>
      </Form>
    </div>
  )
}
export default TransactionNew
